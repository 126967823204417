// Core
import React, { useRef, useEffect, useContext } from "react"
import TransitionLink from "gatsby-plugin-transition-link"
import { ContextDispatch } from "./../../context/Context"

// InView detector
import { InView } from "react-intersection-observer"

// Components
import ScrollSection from "../locomotiveScroll/scrollSection"

//SVG
import Arrow from "./../../../static/images/svg/next-arrow.inline.svg"

// Styles
import styles from "./styles.module.scss"

const ContactEmailSection = props => {
  const { title } = props,
    { url } = props,
    { contact } = props,
    { heading } = props

  const storeDispatch = useContext(ContextDispatch)

  const exitAnim = (exit, node) => {
    storeDispatch({ type: "SWIPE_IN" })
  }

  const entryAnim = (entry, node) => {
    storeDispatch({ type: "SWIPE_OUT" })
  }

  const contactRef = useRef()

  let link, headingText

  if (!contact) {
    link = (
      <TransitionLink
        href={`mailto: ${title}`}
        exit={{
          trigger: ({ exit, node }) => exitAnim(exit, node),
          length: 0.6,
          zIndex: 2,
        }}
        entry={{
          trigger: ({ entry, node }) => entryAnim(entry, node),
          delay: 1,
          length: 1,
          zIndex: 1,
        }}
      >
        <span className={styles.linktext}>{title}</span>{" "}
        <span className={styles.arrowWrap}>
          <span className={styles.arrow}>
            <Arrow />
          </span>
          <span className={styles.arrow}>
            <Arrow />
          </span>
        </span>
      </TransitionLink>
    )

    headingText = heading
  } else {
    link = (
      <p>
        <span className={styles.linktext}>{`Get In Touch`}</span>{" "}
        <span className={styles.arrowWrap}>
          <span className={styles.arrow}>
            <Arrow />
          </span>
          <span className={styles.arrow}>
            <Arrow />
          </span>
        </span>
      </p>
    )

    headingText = `Ready To Talk?`
  }

  const openContact = () => {
    // e.preventDefault()
    if (window) {
      let modal = document.querySelector(".contactModal")
      if (contactRef.current.querySelector("p") && modal) {
        modal.setAttribute("data-contact-open", true)
      }
    }
  }

  useEffect(() => {
    const { current } = contactRef

    current.addEventListener("click", openContact)

    return () => {
      current.removeEventListener("click", openContact)
    }
  })

  return (
    <ScrollSection>
      <InView triggerOnce={true} threshold={0.2}>
        {({ inView, ref, entry }) => (
          <section
            className={styles.nextpage}
            ref={ref}
            inview={inView.toString()}
          >
            <div className="container">
              <div>
                <div className={styles.alignCenter} data-scroll>
                  <p className={`subheading ${styles.subheading}`}>
                    {headingText}
                  </p>
                  <h3
                    className={styles.nextlink}
                    ref={contactRef}
                  >
                    {" "}
                    {link}
                  </h3>
                </div>
              </div>
            </div>
          </section>
        )}
      </InView>
    </ScrollSection>
  )
}

export default ContactEmailSection
