import React, { useContext, useEffect, useRef } from "react"
import { ContextState } from "./../../context/Context"
import Select from "react-select"
import { isMobile } from "react-device-detect"

// Styles
import style from "./style.module.scss"

const Filter = ({ options, setFieldValue, fieldValue, maxWidth = "100%" }) => {
  const optionsToPass = options.options.map(option => ({
    value: option,
    label:
      option === "Designation" ||
      option === "Location" ||
      option === "Team" ||
      option === "Work type"
        ? "All"
        : option,
  }))
  const key = options.field
  const fieldValueToPass = { value: fieldValue[key], label: fieldValue[key] }
  const store = useContext(ContextState)
  const selectRef = useRef(null)
  const customStyles = {
    control: (base, state) => ({
      ...base,
      boxShadow: "none",
      outline: store.keyAccess && state.isFocused ? `#ed9c78 auto 4px` : ``,
      outlineStyle: store.keyAccess && state.isFocused ? `solid` : ``,
      backgroundColor: "transparent",
      borderTop: "none",
      borderColor: "#17213815",
      borderLeft: "none",
      borderRight: "none",
      "&:hover": { borderColor: "#17213815" },
      "&:hover": { cursor: "text" },
      borderRadius: 0,
      maxWidth: maxWidth,
      width: "100%",
    }),
    container: base => ({
      ...base,
      width: "100%",
      maxWidth: maxWidth,
    }),
    indicatorSeparator: base => ({
      ...base,
      width: 0,
    }),
    indicatorsContainer: base => ({
      ...base,
      cursor: "pointer",
    }),
    valueContainer: base => ({
      ...base,
      cursor: "pointer",
    }),
    options: base => ({
      ...base,
      maxWidth: maxWidth,
      width: "100%",
    }),
    input: base => ({
      ...base,
      fontSize: "18px",
    }),
    menu: base => ({
      ...base,
      fontSize: "18px",
    }),
    menuList: base => ({
      ...base,
      zIndex: 11,
    }),
    singleValue: base => ({
      ...base,
      fontSize: "18px",
    }),
  }

  const pageScrollStop = () => {
    window.scroll.stop()
  }

  const pageScrollResume = () => {
    window.scroll.start()
  }

  const onMenuOpen = () => {
    if (!isMobile) {
      setTimeout(() => {
        const { menuListRef } = selectRef.current.select
        menuListRef.addEventListener("mouseover", pageScrollStop)
        menuListRef.addEventListener("mouseout", pageScrollResume)
      }, 1)
    }
  }

  const onMenuClose = () => {
    !isMobile && pageScrollResume()
  }

  return (
    <div className={style.field}>
      <div className={style.input}>
        <Select
          options={optionsToPass}
          placeholder={``}
          name={`fieldType`}
          value={fieldValueToPass}
          id={`fieldType`}
          ref={selectRef}
          styles={customStyles}
          onChange={e => {
            setFieldValue(key, e)
          }}
          onMenuOpen={onMenuOpen}
          onMenuClose={onMenuClose}
        />
      </div>
    </div>
  )
}

export default Filter
