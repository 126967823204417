// Core
import React from "react"
import Img from "gatsby-image"

// InView detector
import "intersection-observer"
import { InView } from "react-intersection-observer"

// Components
import ScrollSection from "../locomotiveScroll/scrollSection"
import SplitHeading from "../split-big-heading/split-big-heading"
import SplitText from "../split-text/split-text"
import Link from "./../link/link"
import VideoComponent from "./../video/video"
import JsonText from "../_jsonText"

//SVG
import Circle from "./../../../static/images/svg/pxcircle.inline.svg"

// Styles
import styles from "./hero.module.scss"
import Button from "../button/button"

const Hero = props => {
  const { heroData } = props,
    { colourScheme } = props,
    { imageAlign } = props,
    { bottomBox } = props,
    { notCircle } = props

  let mediaContent, textContent
  let videoUrl = heroData.heroVideoVimeoCode

  if (videoUrl) {
    //   //return vimeo component
    mediaContent = (
      <VideoComponent
        videoProps={videoUrl}
        fluid={heroData.heroImage.fluid}
        alt={heroData.heroImage.description}
      />
    )
  } else {
    //return static image component

    mediaContent = (
      <div className={styles.imageWrap}>
        <Img
          fluid={heroData.heroImage.fluid}
          alt={heroData.heroImage.description}
        />
      </div>
    )
  }

  textContent = (
    <div className={styles.heading} data-scroll>
      <SplitHeading h1={true} classString={`h1`}>
        {heroData.heroTitle}
      </SplitHeading>

      {heroData.heroDetailsRich && (
        <div className={`subheading`}>
          <JsonText
            content={heroData.heroDetailsRich}
            split={true}
            small={true}
          />
        </div>
      )}

      {heroData.heroSubtitle && (
        <SplitText classString={`h3`}>{heroData.heroSubtitle}</SplitText>
      )}

      {heroData.heroText &&
        (heroData.heroText?.raw ? (
          <JsonText content={heroData.heroText} split={true} />
        ) : (
          <SplitText classString={`copy ${styles.copy}`}>
            {heroData.heroText}
          </SplitText>
        ))}

      {heroData.heroLink && (
        <Link
          label={heroData?.heroCtaText}
          sublabel={heroData?.isNewSummit ? `(INVITE ONLY)` : `(CLICK HERE)`}
          destination={heroData.heroLink.buttonDestination}
          target={heroData.heroLink.buttonTarget}
        />
      )}
      {heroData?.btn && (
        <div className={styles.heroBottomBtn}>
          <Button buttonTheme={"light"} buttonProps={heroData.btn} />
        </div>
      )}
    </div>
  )

  const getHeroColors = color => {
    switch (color) {
      case "dark":
        return styles.herodark
      case "light":
        return styles.herolight
      case "full-dark":
        return styles.herofulldark
      default:
        return styles.heroblue
    }
  }

  return (
    <ScrollSection zindex={2}>
      <section
        className={`${styles.hero} ${getHeroColors(colourScheme)}`}
        data-image-alignment={imageAlign}
      >
        <div className="container">
          <div className={`cols flex ${styles.cols}`}>
            <InView triggerOnce={true} threshold={0.02}>
              {({ inView, ref, entry }) => (
                <div
                  className={`col m12 t5 ${styles.text}`}
                  data-scroll
                  data-scroll-speed={0.6}
                  data-scroll-delay={0.6}
                  ref={ref}
                  inview={inView.toString()}
                >
                  {textContent}
                </div>
              )}
            </InView>
            <InView triggerOnce={true} threshold={0.02}>
              {({ inView, ref, entry }) => (
                <div
                  className={`col m12 t5 ${
                    !bottomBox ? styles.hideBottomBox : ""
                  } ${styles.heromedia}`}
                  data-scroll
                  ref={ref}
                  inview={inView.toString()}
                >
                  <div className={styles.heroImageParallax}>
                    <div className={styles.heroImageTransform}>
                      <div className={styles.heroImageInner}>
                        {!notCircle && <Circle />}
                        {mediaContent}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </InView>
          </div>
        </div>
      </section>
    </ScrollSection>
  )
}

export default Hero
