// Core
import React, { useEffect, useRef, useState, useCallback } from "react"

// Components
import SplitText from "../split-text/split-text"
import JsonText from "../_jsonText"

// Styles
import styles from "./accordion-item.module.scss"

const AccordionItem = props => {
  const title = props.content?.title,
    copy = props.content?.copy?.copy,
    { setCurrentActive } = props,
    { currentActive } = props,
    { dataItem } = props,
    { darkMode } = props

  const accordion = useRef(),
    textRef = useRef()

  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (window.innerWidth < 1024) {
      setIsOpen(false)
    } else {
      setIsOpen(false)
    }

    //updates when active item updates
    // Can never have no items open, 1 is open by default
    if (currentActive === dataItem + 1) {
      setIsOpen(true)

      if (window.innerWidth >= 1240) {
        textRef.current.style.height = textRef.current.scrollHeight + 120 + `px`
        textRef.current.style.maxHeight =
          textRef.current.scrollHeight + 60 + `px`
      } else {
        textRef.current.style.maxHeight =
          textRef.current.scrollHeight + 20 + `px`
      }
    } else {
      setIsOpen(false)
      textRef.current.style.maxHeight = 0 + `px`
    }

    if (window.scroll) {
      if (window.scroll.update) {
        setTimeout(() => {
          window.scroll.update()
        }, 601)
      }
    }
  }, [currentActive, dataItem])

  const open = useCallback(() => {
    //data item begins at 0 but we need first item to start at 1
    setCurrentActive(dataItem + 1)
  }, [setCurrentActive, dataItem])

  const close = useCallback(() => {
    setCurrentActive(1)
  }, [setCurrentActive])

  useEffect(() => {
    const { current } = accordion

    // const heightFunction = () => {
    //   const mainListParent = document.querySelector(".accordion-main")

    //   if (window.innerWidth < 1024) {
    //     console.log("mobile")
    //     mainListParent.style.height = "auto"
    //   } else {
    //     console.log("desktop")
    //   }
    // }

    const clickFunction = () => {
      if (dataItem + 1 === currentActive) {
        close()
      } else {
        open()
      }
    }

    current.addEventListener("click", clickFunction)

    return () => {
      current.removeEventListener("click", clickFunction)
    }
  }, [currentActive, dataItem, open, close])

  return (
    <li
      className={`${styles.singleaccordion} ${darkMode && styles.darkAccItem}`}
      ref={accordion}
      data-open={isOpen}
    >
      <span className={`h3 ${styles.singleaccordiontitle}`}>{title}</span>
      <span
        className={`copy copy--s ${styles.singleaccordioncopy}`}
        ref={textRef}
      >
        {copy &&
          (copy.raw ? (
            <JsonText content={copy} split={true} small={true} />
          ) : (
            <SplitText>{copy}</SplitText>
          ))}
      </span>
      <button className={styles.openclose}>
        <span></span>
        <span></span>
      </button>
    </li>
  )
}

export default AccordionItem
