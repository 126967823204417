import React, { useContext, useEffect } from "react"
import Accordion from "../../components/accordion-and-image/accordion-and-image"
import CareerJobSection from "../../components/career-job-section/careerJobSection"
import ContactEmailSection from "../../components/contact-email-section"
import Footer from "../../components/footer/footer"
import Hero from "../../components/hero/hero-left"
import NextPage from "../../components/next-page/next-page"
import { ContextDispatch } from "../../context/Context"

//
import heroImg from "../../images/focus/aegis-careers-hero.png"

const CareersPage = props => {
  const page = props,
    pageContext = page?.pageContext,
    templateData = page?.data?.contentfulTemplate6

  const storeDispatch = useContext(ContextDispatch)

  useEffect(() => {
    const header = () => {
      storeDispatch({ type: "HEADER_LIGHT" })
    }
    header()
  }, [storeDispatch])

  const data = {
    heroData: {
      heroImage: {
        fluid: {
          src: heroImg,
          aspectRatio: 0.84,
          sizes: "(max-width: 1200px) 100vw, 1200px",
        },
        alt: "hero image",
      },
      heroTitle: templateData?.heroTitle,
      heroText: templateData?.heroDescription,
      btn: {
        buttonText: templateData?.heroCtaText,
        typeOfLink: { buttonDestination: templateData?.heroCtaLink },
      },
    },
    nextPage: {
      heading: templateData?.nextPage?.heading,
      linkTitle: templateData?.nextPage?.linkTitle,
      page: {
        slug: templateData?.nextPage?.page?.slug,
      },
    },
    contactDetails: {
      heading: templateData?.contactText,
      linkTitle: templateData?.contactEmail,
    },
  }
  const accData = {
    accordionImageItems: templateData?.careersBenefits.map(
      ({ title, image, description }) => ({
        title,
        copy: {
          copy: description,
        },
        image: {
          fluid: image?.fluid,
          description: image?.title,
        },
      })
    ),
    accordionImageTitle: templateData?.benefitsTitle,
  }
  let nextpageblock, accordionblock
  if (data.nextPage) {
    nextpageblock = (
      <NextPage
        heading={data.nextPage.heading}
        title={data.nextPage.linkTitle}
        url={`/` + data.nextPage.page.slug}
      />
    )
  }
  if (accData.accordionImageItems) {
    accordionblock = (
      <Accordion content={accData} darkMode={true} titleXl={true} />
    )
  }

  return (
    <React.Fragment>
      <Hero
        imageAlign={`left`}
        bottomBox={false}
        heroData={data.heroData}
        colourScheme={"full-dark"}
        notCircle={true}
      />
      {accordionblock}
      <CareerJobSection jobs={pageContext} />
      <ContactEmailSection
        heading={data.contactDetails.heading}
        title={data.contactDetails.linkTitle}
        url={``}
      />
      {nextpageblock}
      <Footer />
    </React.Fragment>
  )
}

export default CareersPage

export const pageQuery = graphql`
  query template6PageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulTemplate6(slug: { eq: $slug }) {
      ...template6Query
    }
  }
`
