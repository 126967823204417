// Core
import React, { useState } from "react"
// import {gsap} from "gsap"

// InView detector
import { InView } from "react-intersection-observer"

// Components
import AccordionItem from "../accordion-and-image-item/accordion-item"
import ScrollSection from "../locomotiveScroll/scrollSection"
import Img from "gatsby-image"

// Styles
import styles from "./accordion-and-image.module.scss"

const Accordion = props => {
  const { content, titleXl, darkMode } = props
  const initialActive = 0

  const [currentActive, setCurrentActive] = useState(initialActive)

  const contentList = content.accordionImageItems,
    title = content.accordionImageTitle
  /*
   ** - Accordion active value is initially set to 0
   ** - This is updated to 1 when the InView is triggered
   ** - Has a delay of .9s so that the animation is not missed because of build ons
   */
  const openAccordions = x => {
    if (x) {
      setTimeout(() => {
        setCurrentActive(1)
      }, 900)
    }
  }

  return (
    <ScrollSection>
      <section
        className={
          darkMode ? styles.accordion + " " + styles.accDark : styles.accordion
        }
      >
        <div className="container">
          <InView triggerOnce={true} threshold={0.2} onChange={openAccordions}>
            {({ inView, ref, entry }) => (
              <div
                className="cols flex flex--center"
                ref={ref}
                inview={inView.toString()}
              >
                {title ? (
                  <div className="col m12 t10" data-scroll>
                    {titleXl ? (
                      <h1 className={`${styles.titleXl}`}>{title}</h1>
                    ) : (
                      <h2 className={`subheading ${styles.subheading}`}>
                        {title}
                      </h2>
                    )}
                  </div>
                ) : (
                  ``
                )}
                <div
                  className={`col m12 t5 ${styles.accordionwrap}`}
                  data-scroll
                  data-item-open={currentActive}
                >
                  <ul className={`accordion-main ${styles.main}`}>
                    {contentList.map((item, index) => (
                      <AccordionItem
                        darkMode={darkMode}
                        content={item}
                        key={index}
                        setCurrentActive={setCurrentActive}
                        currentActive={currentActive}
                        dataItem={index}
                      />
                    ))}
                  </ul>
                </div>
                <div
                  className={`col m12 t5 ${styles.media}`}
                  data-scroll
                  data-item-open={currentActive}
                >
                  <div className={styles.imageWrap}>
                    {contentList.map((item, index) => (
                      // <HoverItem
                      //   key={index}
                      //   currentActive={currentActive}
                      //   dataItem={index}
                      // />
                      <div className={styles.imageInner} key={index}>
                        <Img
                          fluid={item.image.fluid}
                          alt={item.image.description}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </InView>
        </div>
      </section>
    </ScrollSection>
  )
}

export default Accordion
