import React, { useEffect, useMemo, useState, useContext } from "react"
import InView from "react-intersection-observer"
import ScrollSection from "../locomotiveScroll/scrollSection"
import styles from "./styles.module.scss"
import FilteredJobs from "../filtered-jobs/FilteredJobs"
import Filter from "../filter/filter"
import gsap from "gsap"
import LocomotiveScroll from "locomotive-scroll"
import Scroll from "../locomotiveScroll"
// import ScrollTrigger from "gsap/dist/s"

// Context
import { ContextState } from "./../../context/Context"

const CareerJobSection = ({ jobs }) => {
  const array = jobs.jobs

  const [jobsToShow, setJobs] = useState([])
  const storeState = useContext(ContextState)

  const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index
  }

  let designationOptions = array.map(job => job.title).filter(onlyUnique),
    locationOptions = array
      .map(job => job.offices[0].name !== "Remote" && job.offices[0].name)
      .filter(onlyUnique),
    teamOptions = array.map(job => job.departments[0].name).filter(onlyUnique),
    workTypeOptions = ["Onsite", "Remote"]

  const options = [
    {
      field: "role",
      options: ["Designation", ...designationOptions],
    },
    {
      field: "location",
      options: ["Location", ...locationOptions],
    },
    {
      field: "team",
      options: ["Team", ...teamOptions],
    },
    {
      field: "workType",
      options: ["Work type", ...workTypeOptions],
    },
  ]

  const [filters, setFilters] = useState({
    location: options[1].options[0],
    workType: options[3].options[0],
    role: options[0].options[0],
    team: options[2].options[0],
  })

  const handleFilters = (key, val) => {
    setFilters(prev => {
      return { ...prev, [key]: val.value }
    })
  }

  const stackedFilters = options.splice(1)

  const allJobs = [
    {
      workType: "remote",
      title: "Ascertain",
      city: "USA",
      type: "ascertain",
      designation: "Front-End",
    },
    {
      workType: "remote",
      title: "Ascertain",
      city: "USA",
      type: "ascertain",
      designation: "Full-Stack",
    },
    {
      workType: "onsite",
      city: "UK",
      type: "aegis",
      designation: "SQA",
    },
    {
      workType: "onsite",
      city: "Pakistan",
      type: "ascertain",
      designation: "Back-End",
    },
    {
      workType: "remote",
      title: "Aegis Ventures",
      city: "UK",
      type: "aegis",
      designation: "Front-End",
    },
    {
      workType: "remote",
      city: "Pakistan",
      type: "ascertain",
      designation: "Front-End",
    },
  ]

  useEffect(() => {
    const jobsToMap = array.filter(
      jobs =>
        (filters.location === "Location" ||
          jobs.offices[0].name.toLowerCase() ===
            filters.location.toLowerCase()) &&
        (filters.role === "Designation" ||
          jobs.title.toLowerCase() === filters.role.toLowerCase()) &&
        (filters.workType === "Work type" ||
          (filters.workType === "Onsite"
            ? jobs.offices[0].name !== "Remote"
            : jobs.offices[0].name === "Remote")) &&
        (filters.team === "Team" ||
          jobs.departments[0].name.toLowerCase() === filters.team.toLowerCase())
    )
    setJobs(jobsToMap)
  }, [filters])

  const jobsFromProps = useMemo(() => {
    const restructeredJobs = {}
    for (let i = 0; i < jobsToShow.length; i++) {
      const job = jobsToShow[i]
      const department = job.departments[0].name

      const isAdded = restructeredJobs[department] !== undefined
      if (isAdded) {
        restructeredJobs[department].push(job)
      } else {
        restructeredJobs[department] = [job]
      }
    }
    return restructeredJobs
  }, [jobsToShow])
  console.log("jobs >>", jobsFromProps)

  useEffect(() => {
    let url = window.location.href
    let string = url.substring(url.lastIndexOf("?") + 1)
    let array = string?.split("=")
    const sectionId = array?.[1]
    if (sectionId && !storeState.loader && window.scroll.scrollTo) {
      const jobsSection = document.querySelector("#" + sectionId)
      window.scroll.scrollTo(jobsSection, { offset: 125 })
    }
  }, [storeState.loader])

  return (
    <ScrollSection>
      <section id="jobs" className={styles.section}>
        <div className={styles.container}>
          <InView triggerOnce={true} threshold={0.02}>
            {({ inView, ref, entry }) => (
              <div
                className={styles.filterBox}
                ref={ref}
                inview={inView.toString()}
              >
                <div className={styles.flexStyles}>
                  <Filter
                    options={options[0]}
                    fieldValue={filters}
                    setFieldValue={handleFilters}
                  />
                </div>
                <div className={styles.innerBox}>
                  {stackedFilters.map((item, i) => (
                    <div className={styles.flexStyles} key={i}>
                      <Filter
                        fieldValue={filters}
                        setFieldValue={handleFilters}
                        options={item}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </InView>
          <div className={styles.jobHolder}>
            <FilteredJobs filteredJobList={jobsFromProps} />
          </div>
        </div>
      </section>
    </ScrollSection>
  )
}

export default CareerJobSection

// const data ={
//   Aegis: [{}, {}],
//   Dep2: [{}, {}]
// }
