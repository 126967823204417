// Core
import React from "react"

//SVG
import Arrow from "./../../../static/images/svg/next-arrow.inline.svg"

// Styles
import styles from "./link.module.scss"

const Link = props => {
  const { theme } = props,
    { label } = props,
    { sublabel } = props,
    { destination } = props,
    { target } = props
  
  return (
    <h3
      className={`${styles.nextlink} ${theme === `light` ? styles.light : ``}`}
    >
      <a
        href={destination}
        className={styles.link}
        target={target === "New Tab" ? "_blank" : ""}
        rel={target === "New Tab" ? "noreferrer" : ""}
      >
        <span className={styles.linktext}>
          {label} {sublabel && <span>{sublabel}</span>}
        </span>{" "}
        <span className={styles.arrowWrap}>
          <span className={styles.arrow}>
            <Arrow />
          </span>
          <span className={styles.arrow}>
            <Arrow />
          </span>
        </span>
      </a>
    </h3>
  )
}

export default Link
