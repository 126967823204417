import React from "react"
import InView from "react-intersection-observer"
import Button from "../button/button"
import styles from "./styles.module.scss"

const FilteredJobs = ({ filteredJobList }) => {
  console.log(filteredJobList, "jobs")
  return (
    <div className={styles.listContainer}>
      {filteredJobList ? (
        Object.entries(filteredJobList)
          .sort()
          .map((job, i) => (
            <div className={styles.listItemContainer} key={i}>
              {job[0] && (
                <InView triggerOnce={true} threshold={0.02} key={i}>
                  {({ inView, ref, entry }) => (
                    <div ref={ref} inview={inView.toString()}>
                      <p className={styles.title} data-scroll>
                        {job[0]}
                      </p>
                    </div>
                  )}
                </InView>
              )}
              {job[1].map((item, i) => (
                <InView triggerOnce={true} threshold={0.02} key={i}>
                  {({ inView, ref, entry }) => (
                    <div ref={ref} inview={inView.toString()}>
                      <div className={styles.item}>
                        <div className={styles.itemHead} data-scroll>
                          <span>{item.offices[0].name}</span>
                          <span>{job[0]}</span>
                        </div>
                        <div className={styles.itemBody} data-scroll>
                          {item?.title && (
                            <div>
                              <p className={styles.designation}>{item.title}</p>
                            </div>
                          )}
                          <Button
                            buttonTheme={"dark"}
                            buttonProps={{
                              buttonText: "APPLY",
                              typeOfLink: {
                                buttonTarget: "New Tab",
                                buttonDestination: `https://boards.greenhouse.io/aegisventures/jobs/${item.id}`,
                              },
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </InView>
              ))}
            </div>
          ))
      ) : (
        <div className={styles.textCenter}>
          <p>Nothing to show</p>
        </div>
      )}
    </div>
  )
}

export default FilteredJobs
